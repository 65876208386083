import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components Global
import Modal from 'client/components/Modals/Modal'
import { Row, Column } from 'client/components/Columns'
import Form from 'client/components/Form'
import BtnSubmit from 'client/components/button/BtnSubmit'
import getFormValue from 'client/util/getFormValue'

// Componets Local
import {
  BusinessName,
  BusinessBranchName,
  BusinessUsername
} from '../BusinessForms'

// Actions Redux
import { createBusiness } from 'client/core/redux/Businesses/BusinessesActions'


class BusinessCreateModal extends Component {
  handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(this.refs[formName])

    if (params) {
      params.type = 'delivery'

      this.props.dispatch(createBusiness({
        body: params,
        loadId: formName,
        modalId: 'businessCreateModal',
        props: this.props,
        redirect: {
          url: `/`,
          element: 'username'
        },
        toastMsg: 'สร้างธุรกิจแล้ว'
      }))
    }
  }

  render() {
    const id = "businessCreateModal"

    return (
      <Modal
        id={id}
        headerTitle="สร้างระบบจัดการขนส่ง"
        headerLeft
        headerDescription="กรอกรายละเอียดเกี่ยวกับกิจการของคุณ เพื่อสร้างระบบจัดการขนส่ง"
        width="500px">

        <div className="pd-20">
          <Row>
            <Form
              recheckForm
              ref={id}
              name={id}
              submit={(e) => this.handleSubmit(e, id)}>

              <Column col="column col-xs-12">
                <BusinessUsername />
                <BusinessName />
                <BusinessBranchName />
              </Column>

              <Column col="column col-xs-12 mg-top-10 text-align-right">
                <BtnSubmit
                  loadingId={id}
                  icon="send"
                  iconAlign="left"
                  text="สร้างธุรกิจ"
                  className="waves-effect waves-light btn btn-submit btn-shadow-none"
                  btnDisabled
                />
              </Column>
            </Form>
          </Row>
        </div>
      </Modal>
    )
  }
}

export default connect()(BusinessCreateModal)
