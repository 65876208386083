import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// imgs
import titleIcon from '../../Businesses/resources/icons/parcel-destination.png'

// redux
import { fetchQueryOrdersMany } from 'delivery/redux/Orders/OrdersActions'


const menuItems = (params, count) => {
  let path = `/${params.username}/parcel-destination`

  return [
    {
      name: 'การรับพัสดุ',
      title: true
    },
    {
      name: 'พัสดุทั้งหมด',
      icon: 'list_alt',
      link: `${path}/all/all`,
      searchLinkActive: `${path}/all/`,
    },
    {
      name: 'พัสดุที่ยังไม่รับ',
      icon: 'playlist_remove',
      link: `${path}/shipping-success/all`,
      searchLinkActive: `${path}/shipping-success/`,
      badge: count && { amount: count.checkin, bg: 'red' }
    },
    {
      name: 'พัสดุที่รับแล้ว',
      icon: 'fact_check',
      link: `${path}/destination-branch/all`,
      searchLinkActive: `${path}/destination-branch/`,
    },

    {
      name: 'การจัดส่งพัสดุ',
      title: true
    },
    {
      name: 'พัสดุที่ยังไม่ส่ง',
      icon: 'playlist_remove',
      link: `${path}/undelivery/all`,
      searchLinkActive: `${path}/undelivery/`,
      badge: count && { amount: count.undelivery, bg: 'red' }
    },
    {
      name: 'กำลังจัดส่ง',
      icon: 'directions_car',
      link: `${path}/delivery/all`,
      searchLinkActive: `${path}/delivery/`,
      badge: count && { amount: count.delivery, bg: 'red' }
    },
    {
      name: 'ส่งไม่สำเร็จ',
      icon: 'car_crash',
      link: `${path}/failed/all`,
      searchLinkActive: `${path}/failed/`,
      badge: count && { amount: count.failed, bg: 'red' }
    },
    {
      name: 'ส่งสำเร็จ',
      icon: 'verified',
      link: `${path}/successed/all`,
      searchLinkActive: `${path}/successed/`
    }
  ]
}


const ParcelDestinationMenuContainer = (props) => {
  const [count, setCount] = useState()
  const { business, location, route, match, history, windowModal, closeWindowModal } = props

  useEffect(() => {
    let cancel = false

    countParcels()

    return () => {
      cancel = true
    }
  }, [
    props.location && JSON.stringify(props.location)
  ])

  const countParcels = (cancel) => {
    const query = {
      authName: 'admin',
      businesses_id: business._id
    }

    props.dispatch(fetchQueryOrdersMany(query, {
      body: {
        multiple: [
          {
            notUseParams: 'businesses_id',
            unwind: '$parcels',
            match: {
              'destinationBusiness.businesses_id': `ObjectId(${business._id})`,
              'parcels.status': 'shipping-success',
            },
            count: true,
          },
          {
            notUseParams: 'businesses_id',
            unwind: '$parcels',
            match: {
              'destinationBusiness.businesses_id': `ObjectId(${business._id})`,
              'parcels.status': 'destination-branch',
            },
            count: true,
          },
          {
            notUseParams: 'businesses_id',
            unwind: '$parcels',
            match: {
              'destinationBusiness.businesses_id': `ObjectId(${business._id})`,
              'parcels.status': 'failed',
            },
            count: true,
            limit: 10,
          }
        ]
      },
      notSaveStore: true,
    })).then(res => {
      if (cancel) return

      if (res.success && res.data.length && res.data.length === 3) {
        const datas = res.data

        setCount({
          checkin: datas[0].data,
          undelivery: datas[1].data,
          failed: datas[2].data
        })
      }
    })
  }

  return (
    <Window
      title="พัสดุปลายทาง"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            match={match}
            items={menuItems(match.params, count)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default connect()(ParcelDestinationMenuContainer)
