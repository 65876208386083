import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/WindowsV2/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// imgs
import titleIcon from 'delivery/modules/Businesses/resources/icons/orders.png'

// Functions
import {
  fetchQueryOrdersMany
} from 'delivery/redux/Orders/OrdersActions'


const menuItems = (params, count) => {
  return [
    {
      name: 'ออเดอร์',
      title: true
    },
    {
      name: 'ทั้งหมด',
      nameShort: 'ทั้งหมด',
      icon: 'article',
      link: `/${params.username}/orders/all/all`
    },
    {
      name: 'ประจำวัน',
      nameShort: 'ประจำวัน',
      icon: 'event',
      link: `/${params.username}/orders/today/all`
    },
    /*{
      name: 'ฉบับร่าง',
      nameShort: 'ฉบับร่าง',
      icon: 'drive_file_rename_outline',
      link: `/${params.username}/orders/draft/all`
    },*/
    {
      name: 'เปิดบิลแล้ว',
      nameShort: 'เปิดบิลแล้ว',
      icon: 'verified',
      link: `/${params.username}/orders/completed/all`
    },
    {
      name: 'ยกเลิกแล้ว',
      nameShort: 'ยกเลิกแล้ว',
      icon: 'highlight_off',
      link: `/${params.username}/orders/canceled/all`
    },
    {
      name: 'กรอกที่อยู่',
      title: true
    },
    {
      name: 'ยังไม่กรอกที่อยู่',
      nameShort: 'ไม่มีที่อยู่',
      icon: 'location_off',
      link: `/${params.username}/orders/no-address/all`,
      badge: count && { amount: count.noAddress, bg: 'red' }
    },
    {
      name: 'กรอกที่อยู่แล้ว',
      nameShort: 'มีที่อยู่',
      icon: 'location_on',
      link: `/${params.username}/orders/have-address/all`
    },
  ]
}


const OrdersMenuContainer = (props) => {
  const [count, setCount] = useState()
  const { route, match, business, setting, location, windowModal, closeWindowModal, history } = props

  useEffect(() => {
    let cancel = false
    countOrder(cancel)

    return () => {
      cancel = true
    }
  }, [JSON.stringify(props.location)])

  const countOrder = (cancel) => {
    const query = {
      authName: 'admin',
      businesses_id: business._id
    }

    let notUseParams

    if (business.mainBranch == true) {
      notUseParams = 'businesses_id'
    }

    props.dispatch(fetchQueryOrdersMany(query, {
      body: {
        notUseParams,
        match: {
          'status': { $ne: 'canceled' },
          'haveAddress': false,
          '$or': [
            { customer_address: null },
            { receiver_customer_address: null }
          ]
        },
        count: true,
      },
      notSaveStore: true,
    })).then(res => {
      if (cancel) return

      if (res.success && res.data) {
        setCount({
          noAddress: res.data,
        })
      }
    })
  }


  return (
    <Window
      title="ออเดอร์"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            /*btnAddSubmit={{
              onClick: () => createOrderForSellOnline(props),
              loadingId: 'createOrderDraft',
              text: 'สร้างบิลใหม่'
            }}*/
            items={menuItems(match.params, count)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location,
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )

}

export default connect()(OrdersMenuContainer)
