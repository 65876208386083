import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'

// Componets Global
import { Row, Column } from 'components/Columns'
import Modal from 'components/Modals/Modal'
import FormValidation from 'components/Form'
import BtnSubmit from 'components/button/BtnSubmit'
import getFormValue from 'utils/getFormValue'

// Components Local
import {
  CustomerCorporate,
  CustomerTaxId,
  CustomerAuthPhone,
  CustomerEmail,
  CustomerLineId,
  CustomerAddressNo,
  CustomerAddressGeo
} from '../CustomerForms'

// Actions Redux
import { createQueryCustomerOne } from 'delivery/redux/Customers/CustomersActions'


const CustomerCreateModal = (props) => {
  const formRef = useRef()
  const [data, setData] = useState()
  const { id, business, dispatch } = props

  const handleSubmit = (e, formName) => {
    e.preventDefault()
    const params = getFormValue(formRef.current)

    if (params) {
      if (props.onSubmit) {
        props.onSubmit(params, formName)
      } else {
        const query = {
          authName: 'admin',
          businesses_id: business._id
        }

        dispatch(createQueryCustomerOne(query, {
          body: {
            create: {
              value: params
            }
          },
          loadId: formName,
          modalId: formName,
          toastMsg: 'เพิ่มรายชื่อลูกค้าแล้ว'
        })).then(res => {
          if (res.success && res.data) {
            if (props.onSelect) {
              props.onSelect(res.data)
            }
          }
        })
      }
    }
  }

  const handleChangeAuthPhone = (res) => {
    if (res && res.data) {
      setData(res.data)
    } else {
      setData()
    }
  }

  return (
    <Modal
      id={id}
      headerTitle="เพิ่มรายชื่อลูกค้า"
      headerLeft
      width="550px">

      <div className="pd-20">
        <FormValidation
          ref={formRef}
          name={id}
          dataChange={JSON.stringify(data)}
          submit={(e) => handleSubmit(e, id)}>

          <Row>
            <Fragment>
              <Column col="column col-xs-12 mg-top-20">
                <CustomerCorporate />
              </Column>
              <Column col="column col-xs-12">
                <CustomerTaxId required={props.required} />
              </Column>
              <Column col="column col-xs-12">
                <CustomerAuthPhone
                  business={business}
                  getFetchData={handleChangeAuthPhone}
                  required
                />
              </Column>
              <Column col="column col-xs-12">
                <CustomerEmail />
              </Column>
              <Column col="column col-xs-12">
                <CustomerLineId />
              </Column>
            </Fragment>

            <Column col="column col-xs-12 text-align-right">
              <BtnSubmit
                loadingId={id}
                icon="send"
                iconAlign="left"
                text="เพิ่มรายชื่อลูกค้า"
                className="btn btn-success btn-shadow-none height-35 line-height-35"
                btnDisabled
              />
            </Column>
          </Row>
        </FormValidation>
      </div>
    </Modal>
  )
}

export default connect()(CustomerCreateModal)
