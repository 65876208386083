import React from 'react'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// img
import titleIcon from 'delivery/modules/Businesses/resources/icons/reports.png'


const menuItems = (params) => {
  const { username } = params
  const path = `/${username}/reports`

  return [
    {
      name: 'รายงาน',
      title: true
    },
    {
      name: 'ออเดอร์',
      nameShort: 'ออเดอร์',
      icon: 'receipt',
      link: `${path}/orders`,
    },
    {
      name: 'พัสดุ',
      nameShort: 'พัสดุ',
      icon: 'archive',
      link: `${path}/parcels`,
    },
    {
      name: 'การชำระเงิน',
      nameShort: 'การชำระเงิน',
      icon: 'credit_card',
      link: `${path}/parcels-payment`,
    },
    // {
    //   name: 'พัสดุปลายทาง',
    //   nameShort: 'พัสดุปลายทาง',
    //   icon: 'pin_drop',
    //   link: `${path}/parcel-destination`,
    // },
    // {
    //   name: 'ลูกค้า',
    //   nameShort: 'ลูกค้า',
    //   icon: 'person',
    //   link: `${path}/customers`,
    // }
  ]
}



const ReportsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props

  return (
    <Window
      title="รายงาน"
      titleIcon={titleIcon}
      linkClose={`/${match.params.username}`}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkHelp="">

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems(match.params)} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default ReportsMenuContainer
