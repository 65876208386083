import AppDeliveryContainer from 'delivery/AppDeliveryContainer'
import SignIn from 'delivery/modules/Auth/pages/SignIn'
import SignUp from 'delivery/modules/Auth/pages/SignUp'
import SignOut from 'delivery/modules/Auth/pages/SignOut'
import ConfirmAccount from 'delivery/modules/Auth/pages/ConfirmAccount'
import ConfirmAccountToken from 'delivery/modules/Auth/pages/ConfirmAccountToken'
import ForgotPassword from 'delivery/modules/Auth/pages/ForgotPassword'
import ResetPassword from 'delivery/modules/Auth/pages/ResetPassword'
import BusinessesList from 'delivery/modules/Businesses/pages/BusinessesList'

// routes
import BusinessesRoutes from './BusinessesRoutes'


const DashboardRoutes = [
  {
    component: AppDeliveryContainer,
    routes: [
      {
        path: '/',
        exact: true,
        auth: { reqAuth: true },
        component: BusinessesList
      },
      {
        path: '/signin',
        auth: { unAuth: true },
        component: SignIn
      },
      {
        path: '/signup',
        auth: { unAuth: true },
        component: SignUp
      },
      {
        path: '/signout',
        component: SignOut
      },
      {
        path: '/confirm-account',
        auth: { reqAuth: true, active: false },
        component: ConfirmAccount
      },
      {
        path: '/confirm-account-token/:token',
        component: ConfirmAccountToken
      },
      {
        path: '/forgot-password',
        component: ForgotPassword
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword
      },

      ...BusinessesRoutes
    ]
  }
]

export default DashboardRoutes
