import { getCountries, getProvinces } from '../../../databases/geo'

const config = require(`../../../projects/${process.env.APP_PROJECT}/config`).default

const CDN_URL = config.app.cdnUrl

// Export Constants
export const ADD_COUNTRIES = 'ADD_COUNTRIES'
export const ADD_PROVINCES = 'ADD_PROVINCES'

// Export type
export const add_countries = countries => ({ type: ADD_COUNTRIES, countries })
export const add_provinces = provinces => ({ type: ADD_PROVINCES, provinces })

// Export Actions Countries
export const fetchCountries = () => dispatch => {
	return dispatch(add_countries(getCountries()))
}


// Export Actions Provinces
export const fetchProvinces = () => dispatch => {
	return dispatch(add_provinces(getProvinces()))
}

export const fetchProvincesOfCountryCodes = (countryCodes) => dispatch => {
	return dispatch(add_provinces(getProvinces(countryCodes)))
}



// Export function other
export const fetchCountriesOfContinent = (state, continent, noCountryCode = null) => {
	var array = []
	let countries = state.geo.countries.filter(countries => countries.continent === continent && countries.countryCode !== noCountryCode)

	countries.map((value, key) => {
		array[key] = {
			id: value.countryCode,
			label: value.countryNameTh,
			value: value.countryCode,
			img: `${CDN_URL}/common/imgs/icons/flags/${value.countryCode}.png?v=1`
		}
	})

	return array
}

// function get value country in store props.geo.countries
export const getCountry = ({ countries, attribute, countryCode }) => {
	let country = countries.filter(country => country.countryCode === countryCode)[0]

	if (country)
		return country[attribute]

}

// function get value province in store props.geo.provinces
export const getProvince = ({ provinces, countryCode, attribute, provinceId }) => {


	if (provinces) {
		let provinceFilter = provinces.filter(province => province.countryCode === countryCode)[0]


		if (provinceFilter) {
			let province = provinceFilter.provinces.filter(province => province.id === provinceId)[0]

			if (province) return province[attribute]
			else return null
		}

		return null

	} else {
		return null
	}
}


