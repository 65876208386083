// Containers
import OrdersContainer from 'delivery/modules/Orders/containers/OrdersContainer'

// Routes
import OrdersMenuRoutes from './routes/OrdersMenuRoutes'


const OrdersRoutes = [
  {
    path: '/:username/orders',
    component: OrdersContainer,
    routes: [
      ...OrdersMenuRoutes
    ]
  }
]

export default OrdersRoutes
