import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import cuid from 'cuid'
import renderRoutes from 'core/routes/renderRoutes'
import AppDeliveryDashboardContainer from 'delivery/modules/AppDeliveryDashboardContainer'
import App from 'core/modules/App'


const AppDeliveryContainer = (props:any) => {
  useEffect(() => {
    const users_id = localStorage.getItem('users_id')

    if (!users_id) {
      const randomUserId = cuid()
      localStorage.setItem('users_id', randomUserId)
    }
  }, [])


  const params: any = {
    routes: props.route.routes,
    location: props.location
  }

  return (
    <App>
      <AppDeliveryDashboardContainer {...props}>
        { renderRoutes(params) }
      </AppDeliveryDashboardContainer>
    </App>
  )
}

const mapStateToProps = (state:any) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(AppDeliveryContainer)
