import React from 'react'

// Components Customers
import { CustomerShort } from '../../../../Customers/components/CustomersUserInterface'

// Utils
import { getDate } from 'utils/getDateTime/getDateTime'
import { getNumeral } from 'utils/getNumeral'

// Values
import { orderValue } from 'delivery/redux/Orders/OrdersValues'


export const OrderItem = (props) => {
  const { business, data, history, location, match, openModal } = props
  const { orders_id, status } = match.params
  let order = data
  order = orderValue({ order })

  const goto = () => {
    let path = `/${business.username}/orders/${status}/${order._id}`

    if (location.search) {
      path = `${path}${location.search}`
    }

    openModal()
    history.push(path)
  }



  let originBranchName, destinationBranchName

  if (order) {
    originBranchName = order.businesses_branchName

    if (order.destinationBusiness) {
      destinationBranchName = order.destinationBusiness.businesses_branchName
    }
  }

  return (
    <tr
      style={{ userSelect: 'none' }}
      onClick={() => goto()}
      className={orders_id === order._id ? 'trActive' : ''}>

      <td width="5" height="65" className="pd-0" style={{ background: order.statusColor }}></td>

      <td width="50" className="center hidden-xxs hidden-xs hidden-sm">

        { order.statusAddress.code === 'no-address' ?
          <i className="material-icons i-middle" style={{ color: order.statusAddress.statusColor }}>
            { order.statusAddress.statusIcon }
          </i>
        :
          <i className="material-icons i-middle" style={{ color: order.statusColor }}>
            { order.statusIcon }
          </i>
        }
      </td>

      <td className="font-0-9 pd-10 hidden-xlg">
        <div>#{ order.code } ({order.codeInside})</div>
        <div>วันที่ : { getDate(order.created, 'DD/MM/YYYY, HH:mm น.', 'th') }</div>
        <div>ยอดชำระ : ฿{ getNumeral(order.totalNetAmount, 'auto') }</div>
      </td>

      <td className="font-0-9 pd-10 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <div>#{ order.code } ({order.codeInside})</div>
        <div className="font-0-8 color-sub">วันที่ { getDate(order.created, 'DD/MM/YYYY, HH:mm น.', 'th') }</div>
        <div className="font-0-7">{originBranchName} {'-->'} {destinationBranchName}</div>
      </td>

      <td className="pd-0">
        <span
          className={`badge-border`}
          style={{ color: order.statusColor, border: `1px solid ${order.statusColor && order.statusColor}` }}>
          { order.statusName && order.statusName }
        </span>
      </td>

      <td className="pd-5 center hidden-xxs hidden-xs hidden-sm">
        <CustomerShort customer={order.customer} order={order} />
      </td>

      <td className="pd-0">
        { order.channelSales }
      </td>


      <td className="text-right pd-0 hidden-lg hidden-md hidden-sm hidden-xs hidden-xxs">
        <span className="color-price font-1-1">฿{ getNumeral(order.totalPrice, 'auto') }</span>
        <div className="font-0-8 color-sub">ราคา</div>
      </td>
      <td className="pd-3"></td>
    </tr>
  )
}
