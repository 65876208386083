/**
 * Root Reducer
 */
import { combineReducers } from 'redux'

// reducer common
import users from 'core/redux/Users/UsersReducer'
import admins from 'core/redux/Admins/AdminsReducer'
import app from 'core/redux/App/AppReducer'
import progresses from 'core/redux/Progresses/ProgressesReducer'
import auth from 'core/redux/Auth/AuthReducer'
import businesses from 'core/redux/Businesses/BusinessesReducer'
import geo from 'core/redux/Geo/GeoReducer'
import host from 'core/redux/Host/HostReducer'

// reducer
import customers from './Customers/CustomersReducer'
import settings from './Settings/SettingsReducer'
import orders from './Orders/OrdersReducer'

// Combine all reducers into one root reducer
export default combineReducers({
  users,
  admins,
  app,
  progresses,
  auth,
  businesses,
  geo,
  host,

  customers,
  settings,
  orders
})
