import React from 'react'
import { connect } from 'react-redux'
import Scrollbar from 'react-perfect-scrollbar'

// Components Global
import FetchDataListItems from 'components/FetchDataListItems'

// Component Local
import { OrderItem } from './OrderItem'

// Actions Redux
import { fetchOrders } from 'delivery/redux/Orders/OrdersActions'
import { getDate } from 'utils/getDateTime'



const OrdersListItems = (props) => {
  const { business, openSidebar, match } = props
  const { status } = match.params

  const getParams = () => {
    let params = {}

    if (status === 'all') {
      params = { name: 'all', title: 'ทั้งหมด', icon: 'library_books' }
    } else if (status === 'today') {
      params = { name: 'today', title: 'ประจำวัน', icon: 'event' }
    } else if (status === 'draft') {
      params = { name: 'draft', title: 'ฉบับร่าง', icon: 'drive_file_rename_outline' }
    } else if (status === 'no-address') {
      params = { name: 'no-address', title: 'ยังไม่กรอกที่อยู่', icon: 'location_off' }
    } else if (status === 'have-address') {
      params = { name: 'have-address', title: 'กรอกที่อยู่แล้ว', icon: 'location_on' }
    } else if (status === 'completed') {
      params = { name: 'completed', title: 'เปิดบิลแล้ว', icon: 'verified' }
    } else if (status === 'refunded') {
      params = { name: 'refunded', title: 'คืนเงิน', icon: 'restore_page' }
    } else if (status === 'canceled') {
      params = { name: 'canceled', title: 'ยกเลิกแล้ว', icon: 'highlight_off' }
    }

    return params
  }

  let matchValue = {}, or, addFields

  if (status === 'all') {
    matchValue['status'] = { $ne: 'canceled' }
  } else if (status === 'today') {
    matchValue['status'] = { $ne: 'canceled' }
    matchValue['date'] = getDate(new Date(), 'YYYY-MM-DD')
    addFields = {
      'date': {
        '\$dateToString': {
          'format': '%25Y-%25m-%25d',
          'date': '\$created',
          'timezone': "Asia/Bangkok"
        }
      }
    }
  } else if (status === 'no-address') {
    matchValue['status'] = { $ne: 'canceled' }
    matchValue['$or'] = [
      { customer_address: null },
      { receiver_customer_address: null }
    ]

  } else if (status === 'have-address') {
    matchValue['status'] = { $ne: 'canceled' }
    matchValue['customer_address'] = { $ne: null }
    matchValue['receiver_customer_address'] = { $ne: null }




  } else {
    matchValue['status'] = status
  }

  let notUseParams

  if (business.mainBranch == true) {
    notUseParams = 'businesses_id'
  }

  const { title, icon } = getParams()
  const fetchAction = {
    action: fetchOrders,
    query: {
      authName: 'admin',
      businesses_id: business._id
    },
    body: {
      notUseParams,
      addFields,
      match: matchValue,
      or,
      sort: { 'code': -1 },
      pageNumber: 1,
      limit: 25,
      search: {
        value: '',
        attrs: [
          { key: 'code' },
          { key: 'customer_address.authPhone.number' },
          { key: 'receiver_customer_address.authPhone.number' },
        ]
      }
    },
    id: business._id,
  }



  return (
    <div style={{ position: 'absolute', top: '0', left: '0', bottom: '0', right: '0' }}>
      <Scrollbar>
        <FetchDataListItems
          {...props}
          id="ordersList"
          storeName="orders"
          tableClassName=""
          title={title}
          icon={icon}
          fetchAction={fetchAction}
          componentList={OrderItem}
          columnTitle={{
            column1: 'column col-xxs-12',
            column2: 'column col-xxs-12 pd-top-10'
          }}
          notReloadForUrlChange
          dataReloadForUrlChange={status}
          openSidebar={openSidebar}
        />
      </Scrollbar>
    </div>
  )
}

export default connect()(OrdersListItems)

