import { fetchDatas, fetchData, createData, updateData, removeData, fetchApi } from 'core/redux/reduxActions'
import { fetchDatasMany } from 'core/redux/reduxActionsV2'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypesV2'

import uploadsForS3 from 'core/utils/uploadsForS3'
import { checkAppActions } from 'core/redux/App/AppActions'


// Export Constants
const NAME_TYPE = ['CUSTOMERS', 'CUSTOMER']
const name = "customers"

// Type Names and Type Functions
export const {
  ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
  ADDS_MANY, ADDS_MANY_MORE,
} = generateTypeName(NAME_TYPE)

export const {
  adds, adds_more, add, update, remove,
  adds_many, adds_many_more,
} = generateTypeFunctions(NAME_TYPE)




// Display
export const fetchCustomerForUser = (businesses_id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `customer-for-user/${businesses_id}`,
    add: add
  })
}

export const fetchCustomersDisplay = (businesses_id, params = {}) => dispatch => {
  return fetchDatas({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchCustomerDisplay = (businesses_id, _id, params = {}) => dispatch => {
  return fetchData({
    dispatch,
    params,
    api: `${name}-display/${businesses_id}/${_id}`,
    add: add
  })
}


// Export Actions
export const countCustomers = (query, params = {}) => dispatch => {
  const { authName, dbNo, businesses_id } = query

  return fetchApi({
    dispatch,
    params,
    api: `${name}-count/${authName}/${dbNo}/${businesses_id}`,
  })
}

export const checkCustomerDuplicate = (query, params = {}) => dispatch => {
  const { businesses_id } = query


  return fetchApi({
    dispatch,
    params,
    api: `${name}-query/${businesses_id}/check-value-duplicate?${params.query}`
  })
}


export const fetchQueryCustomerOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryCustomersMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatasMany({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    adds_many,
    adds_many_more
  })
}

export const createQueryCustomerOne = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateQueryCustomerOne = (query, params) => dispatch => {
  const { authName, businesses_id, customers_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || customers_id}`,
    update: update,
    updateId: _id || customers_id
  })
}

export const fetchCustomers = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchCustomer = (query, params = {}) => dispatch => {
  const { authName, businesses_id, customers_id } = query


  return fetchData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${customers_id}`,
    add: add
  })
}

export const createCustomer = (query, params) => dispatch => {
  const { authName, businesses_id } = query


  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateCustomer = (query, params) => dispatch => {
  const { authName, businesses_id, customers_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || customers_id}`,
    update: update,
    updateId: _id || customers_id
  })
}

export const uploadImagesCustomer = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams
  const { businesses_id } = query

  checkAppActions(dispatch, params, 'start')
  uploadsForS3({
    files,
    prefix: imagePrefix,
    progressId: params.progressId,
    dispatch,
    businesses_id
  }).then(val => {
    val.map(paths => {
      params.body.paths = paths

      dispatch(updateCustomer(query, params))
    })
  })
}


export const removeCustomer = (query, params) => dispatch => {
  const { authName, businesses_id, customers_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || customers_id}`,
    remove: remove,
    deleteId: _id || customers_id
  })
}





