import React from 'react'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// imgs
import titleIcon from '../resources/imgs/business-settings.png'


const menuItems = ({ params, business, setting }) => {
  return [
    {
      name: 'ข้อมูลกิจการ',
      title: true
    },
    {
      name: 'ข้อมูลทั่วไป',
      icon: 'perm_data_setting',
      link: `/${params.username}/business-settings`,
      exact: true,
    },
    {
      name: 'ตั้งค่าภาษา',
      icon: 'language',
      link: `/${params.username}/business-settings/languages`
    },
    {
      name: 'ข้อมูลบริษัท',
      icon: 'business',
      link: `/${params.username}/business-settings/company`,
      exact: true,
    },
    {
      name: 'ข้อมูลภาษี',
      icon: 'receipt',
      link: `/${params.username}/business-settings/tax`
    },
    {
      name: 'รูปภาพ',
      icon: 'photo_size_select_large',
      link: `/${params.username}/business-settings/pictures`
    },

    /*{
      name: 'ตั้งค่าการ API',
      title: true
    },
    {
      name: 'อีเมล',
      icon: 'email',
      link: `/${params.username}/business-settings/api/email`,
      exact: true,
    },
    {
      name: 'ไลน์แจ้งเตือน',
      iconFa: 'fab fa-line',
      link: `/${params.username}/business-settings/api/line-notify`,
      exact: true,
    },*/

    /*{
      name: 'เทลมเพลตการแจ้งเตือน',
      title: true
    },
    {
      name: 'อีเมล',
      nameShort: 'อีเมล',
      icon: 'email',
      link: `/${params.username}/business-settings/notifications/email`
    },
    {
      name: 'ไลน์แจ้งเตือน',
      nameShort: 'ไลน์แจ้งเตือน',
      iconFa: 'fab fa-line',
      link: `/${params.username}/business-settings/notifications/line-notify`
    },*/

    /*{
      name: 'กลุ่ม',
      title: true
    },
    {
      name: 'กลุ่มลูกค้า',
      nameShort: 'กลุ่มลูกค้า',
      icon: 'group_add',
      link: `/${params.username}/business-settings/groups/customer`
    },*/
    // {
    //   name: 'กลุ่มผู้ดูแลระบบ',
    //   nameShort: 'กลุ่มผู้ดูแล',
    //   iconFa: 'fab fa-line',
    //   link: `/${params.username}/business-settings/groups/admin`
    // },

    // {
    //   name: 'เฟซบุ๊คแชท',
    //   iconFa: 'fab fa-facebook-messenger',
    //   link: `/${params.username}/business-settings/api/facebook`,
    //   exact: true,
    // },

    //...storeMenu
  ]
}

const BusinessSettingsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const params = match.params

  return (
    <Window
      title="จัดการข้อมูลธุรกิจ"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems({ params, business, setting })} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </Window>
  )
}

export default BusinessSettingsMenuContainer
