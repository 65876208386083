import { getGeoOnly } from "utils/getGeo"


export const orderValue = ({ order, lang="local" }) => {
  if (order) {
    let status = getStatus(order.status)
    let statusAddress = getStatusAddress(order)

    const senderAddressData = getAddressData({ addressValue: order.senderAddress, lang })
    const receiverAddressData = getAddressData({ addressValue: order.receiverAddress, lang })
    const paymentMethodValue = getPaymentMethodValue(order.paymentMethod, lang)

    return {
      ...order,
      ...status,
      statusAddress,
      senderAddressData,
      receiverAddressData,
      paymentMethodValue
    }
  }
}

export const orderItemValue = ({ item, lang="local" }) => {
  if (item) {
    const unitValue = getUnit(item.unit)
    const calcTypeValue = getCalculateType(item.calcType)

    return {
      ...item,
      unitValue,
      calcTypeValue
    }
  }
}

export const orderParcelValue = ({ order, parcel, lang="local" }) => {
  if (parcel) {
    const item = order.items.filter(item => item.cuid === parcel.items_cuid)[0]
    const statusValue = parcelStatus.filter(status => status.code === parcel.status)[0]
    const itemValue = orderItemValue({ item })

    return {
      ...parcel,
      itemValue,
      statusValue
    }
  }
}

export const getStatus = (code, lang="local") => {
  const status = statusAll.filter(v => v.id === code)[0]
  let statusName, statusColor, statusIcon

  if (status) {
    statusName = status.name
    statusColor = status.color
    statusIcon = status.icon
  }

  return {
    statusName,
    statusColor,
    statusIcon
  }
}

export const getStatusAddress = (order, lang="local") => {
  let code

  if (order.customer_address == null || order.receiver_customer_address === null) {
    code = 'no-address'
  } else {
    code = 'have-address'
  }

  const status = statusAddress.filter(v => v.id === code)[0]
  let statusName, statusColor, statusIcon

  if (status) {
    statusName = status.name
    statusColor = status.color
    statusIcon = status.icon
  }

  return {
    code,
    statusName,
    statusColor,
    statusIcon
  }
}


export const getPaymentMethodValue = (code, lang) => {
  return paymentMethods.filter(v => v.code === code)[0]
}

export const getUnit = (code, lang="local") => {
  return unitAll.filter(v => v.code === code)[0]
}

export const getCalculateType = (code, lang="local") => {
  return calculateTypesAll.filter(v => v.code === code)[0]
}

export const getAddressData = ({ addressValue, lang }) => {
  let addressData

  if (addressValue) {
    const { name, tel, address, geo } = addressValue || {}
    const data = geo && getGeoOnly({ geo })

    addressData = {
      name: name && name[lang],
      tel: tel && tel.number,
      address: address && address[lang],
      geo: data && data.short
    }
  }

  return addressData
}

export const statusAll = [
  { no: 1, id: 'draft', name: `ร่าง`, icon: 'drive_file_rename_outline', color: '#777777', type: 'status' },
  { no: 2, id: 'completed', name: 'เปิดบิลแล้ว', icon: 'verified', color: '#7cb342', type: 'status' },
  { no: 3, id: 'canceled', name: 'ยกเลิก', icon: 'highlight_off', color: '#d50000', type: 'status' },
]

export const statusAddress = [
  { no: 1, id: 'no-address', name: `ยังไม่กรอกที่อยู่`, icon: 'location_off', color: '#ff0000', },
  { no: 2, id: 'have-address', name: `กรอกที่อยู่แล้ว`, icon: 'location_on', color: '#7cb342' },
]

export const unitAll = [
  {
    'name': 'ชิ้น',
    'code': 'pieces',
    'color': 'green',
  },
  {
    'name': 'กล่อง',
    'code': 'boxes',
    'color': 'pink',
  },
  {
    'name': 'พาเลท',
    'code': 'pallets',
    'color': 'purple',
  },
  {
    'name': 'ถัง',
    'code': 'buckets',
    'color': 'brown',
  },
  {
    'name': 'ลัง',
    'code': 'crates',
    'color': '#5d8326',
  },
  {
    'name': 'กระสอบ',
    'code': 'sacks',
    'color': 'orange',
  },
]

export const calculateTypesAll = [
  {
    'code': 'weight',
    'name': 'คิดตามน้ำหนัก',
    'nameShort': 'น้ำหนัก',
  },
  {
    'code': 'size',
    'name': 'คิดตามขนาด',
    'nameShort': 'ขนาด',
  },
]


export const paymentMethods = [
  {
    'code': 'cash',
    'name': 'ชำระเงินต้นทาง',
    'icon': 'payments',
    'color': '#d100e8',
  },
  {
    'code': 'cash-on-delivery',
    'name': 'ชำระเงินปลายทาง',
    'icon': 'real_estate_agent',
    'color': '#009999',
  },
  {
    'code': 'credit',
    'name': 'เครดิต',
    'icon': 'receipt_long',
    'color': '#1400ee',
  },
];


export const getParcelStatus = (status) => {
  return parcelStatus.filter(parcel => parcel.code === status)[0]
}


//'prepare-shipping', 'shipping', 'shipping-success',



export const parcelStatus = [
  {
    code: 'ready',
    name: 'พร้อมนำส่ง',
    icon: 'archive',
    color: '#f4511e',
    color2: '#f4511e',
  },
  {
    code: 'checkin',
    name: 'ส่งพัสดุที่สาขา',
    icon: 'location_city',
    color: '#6d4c41',
    color2: '#6d4c41',
  },
  // {
  //   code: 'received',
  //   name: 'เข้ารับพัสดุแล้ว',
  //   icon: 'real_estate_agent',
  //   color: '#cddc39',
  //   color2: '#cddc39',
  // },
  {
    code: 'prepare-shipping',
    name: 'เตรียมขนส่ง',
    icon: 'pending_actions',
    color: '#e65100',
    color2: '#e65100',
  },
  {
    code: 'shipping',
    name: 'กำลังขนส่ง',
    icon: 'local_shipping',
    color: '#ffd600',
    color2: '#ffd600',
  },
  {
    code: 'shipping-success',
    name: 'ขนส่งสำเร็จ',
    icon: 'location_on',
    color: '#cddc39',
    color2: '#cddc39',
  },
  {
    code: 'destination-branch',
    name: 'ถึงสาขาปลายทาง',
    icon: 'where_to_vote',
    color: '#827717',
    color2: '#827717',
  },
  {
    code: 'delivery',
    name: 'กำลังจัดส่ง',
    icon: 'directions_car',
    color: '#0288d1',
    color2: '#0288d1',
  },
  {
    code: 'failed',
    name: 'ส่งไม่สำเร็จ',
    icon: 'car_crash',
    color: '#ff0000',
    color2: '#ff0000',
  },
  {
    code: 'successed',
    name: 'ส่งสำเร็จ',
    icon: 'verified',
    color: '#7cb342',
    color2: '#7cb342',
  }
]

export const getOptionsParcelStatus = () => {
  const options = [{ name: 'ทั้งหมด', value: '' }]

  parcelStatus.map(status => {
    options.push({
      name: status.name, value: status.code,
    })
  })

  return options
}


