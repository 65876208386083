import React from 'react'

// Components Global
import ProfileAvatar from 'components/ProfileAvatar'

// Values
import { customerValue } from 'delivery/redux/Customers/CustomersValues'


const CustomerProfileAvater = (props) => {
  let { customer, className } = props

  if (customer && !customer.nameLang) {
    customer = customerValue({ customer })
  }

  return (
    <ProfileAvatar
      circle
      className={className}
      images={customer && customer.images}
      imageSize="m"
      color={customer && customer.avatarColor}
      texts={customer && customer.avatarName || []}
      fontClassName={props.fontClassName}
    />
  )
}

export default CustomerProfileAvater
