// Containers
import BusinessSettingsContainer from 'client/core/modules/BusinessSettings/containers/BusinessSettingsContainer'

// Routes
import BusinessSettingsMenuRoutes from './routes/BusinessSettingsMenuRoutes'


const BusinessSettingsRoutes = [
  {
    path: '/:username/business-settings',
    component: BusinessSettingsContainer,
    routes: [
      ...BusinessSettingsMenuRoutes
    ]
  }
]

export default BusinessSettingsRoutes
