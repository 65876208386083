// containers
import BusinessContainer from 'delivery/modules/Businesses/containers/BusinessContainer'
import BusinessDashboardContainer from 'delivery/modules/Businesses/containers/BusinessDashboardContainer'

// routes common
import AdminsRoutes from 'core/routes/AdminsRoutes'
import UsersRoutes from 'core/routes/UsersRoutes'
import BusinessSettingsRoutes from 'core/routes/BusinessSettingsRoutes'

// routes local
import CustomersRoutes from './routes/CustomersRoutes'
import OrdersRoutes from './routes/OrdersRoutes'
import ParcelsRoutes from './routes/ParcelsRoutes'
import ShippingsRoutes from './routes/ShippingsRoutes'
import ParcelDestinationRoutes from './routes/ParcelDestinationRoutes'
import SettingsRoutes from './routes/SettingsRoutes'
import ReportsRoutes from './routes/ReportsRoutes'


const BusinessesRoutes = [
  {
    path: '/:username',
    component: BusinessContainer,
    routes: [
      {
        path: '/:username',
        auth: { reqAuthBiz: true },
        component: BusinessDashboardContainer,
        routes: [
          ...UsersRoutes,
          ...AdminsRoutes,
          ...BusinessSettingsRoutes,

          ...ReportsRoutes,
          ...SettingsRoutes,
          ...CustomersRoutes,
          ...OrdersRoutes,
          ...ParcelsRoutes,
          ...ShippingsRoutes,
          ...ParcelDestinationRoutes,
        ]
      }
    ]
  }
]

export default BusinessesRoutes
