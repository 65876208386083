import { fetchDatas, fetchData, createData, updateData, removeData } from 'core/redux/reduxActions'
import { generateTypeName, generateTypeFunctions } from 'core/redux/reduxTypes'

import uploadsForS3 from 'core/utils/uploadsForS3'
import { checkAppActions } from 'core/redux/App/AppActions'
import callApi from 'core/utils/apiCaller'

// Export Constants
const NAME_TYPE = ['ORDERS', 'ORDER']
const name = "orders"

// Type Names and Type Functions
export const { ADDS, ADDS_MORE, ADD, UPDATE, REMOVE } = generateTypeName(NAME_TYPE)
export const { adds, adds_more, add, update, remove } = generateTypeFunctions(NAME_TYPE)


// Export Actions
export const fetchOrders = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatas({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const fetchOrder = (query, params = {}) => dispatch => {
  const { authName, businesses_id, orders_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${orders_id}`,
  })
}

export const fetchQueryOrderOne = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}`,
    add: add
  })
}

export const fetchQueryOrdersMany = (query, params = {}) => dispatch => {
  const { authName, businesses_id } = query

  return fetchDatas({
    dispatch,
    params,
    api: `${name}-many/${authName}/${businesses_id}`,
    add: adds,
    addMore: adds_more
  })
}

export const createQueryOrderOne = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const updateQueryOrderOne = (query, params) => dispatch => {
  const { authName, businesses_id, orders_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || orders_id}`,
    update: update,
    updateId: _id || orders_id
  })
}


export const createOrder = (query, params) => dispatch => {
  const { authName, businesses_id } = query

  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/create`,
    add: add
  })
}

export const createOrderForUser = ({ authName, businesses_id }, params) => dispatch => {
  return createData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/create`,
    addMultiple: [
      { storeName: 'orders', add },
      { storeName: 'carts', add: addCarts }
    ]
  })
}

export const updateOrder = (query, params) => dispatch => {
  const { authName, businesses_id, orders_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || orders_id}`,
    update: update,
    updateId: _id || orders_id
  })
}

export const updateQueryOneOrder = (query, params) => dispatch => {
  const { authName, businesses_id, orders_id, _id } = query

  return updateData({
    dispatch,
    params,
    api: `${name}-one/${authName}/${businesses_id}/${_id || orders_id}`,
    update: update,
    updateId: _id || orders_id
  })
}

export const removeOrder = (query, params) => dispatch => {
  const { authName, businesses_id, orders_id, _id } = query

  return removeData({
    dispatch,
    params,
    api: `${name}/${authName}/${businesses_id}/${_id || orders_id}`,
    remove: remove,
    deleteId: _id || orders_id
  })
}

export const uploadImagesOrder = (query, fileParams, params) => dispatch => {
  const { files, imagePrefix } = fileParams

  checkAppActions(dispatch, params, 'start')
  uploadsForS3(files, imagePrefix, params.progressId, dispatch).then(val => {
    val.map(paths => {
      params.body.paths = paths
      dispatch(updateOrder(query, params))
    })
  })
}
