import React, { useMemo, useState } from 'react'

// Components Global
import { Row, Column } from 'components/Columns'
import RadioGroup from 'components/Inputs/RadioGroup'
import TextInput from 'components/Inputs/TextInput'
import InputDropdown from 'components/Inputs/InputDropdown'
import InputNo from 'components/Inputs/InputNo'
import InputPhoneNumber from 'components/Inputs/InputPhoneNumber'
import InputAddress from 'components/Inputs/InputAddress'
import InputDecimal from 'components/Inputs/InputDecimal'
import InputCheckDuplicate from 'components/Inputs/InputCheckDuplicate'
import InputGoogleMapGeo from 'components/Inputs/InputGoogleMapGeo/InputGoogleMapGeo'
import Select from 'components/Inputs/Select'
import TextInputFetch from 'components/Inputs/TextInputFetch'

// Components Local
import { CustomerProfileAvater } from '../CustomersUserInterface'

// Redux Actions
import { fetchCustomers } from 'delivery/redux/Customers/CustomersActions'

// Values
import { customerValue } from 'delivery/redux/Customers/CustomersValues'

// Actions
import { fetchQueryCustomerOne, checkCustomerDuplicate } from 'delivery/redux/Customers/CustomersActions'
import TextInputV2 from 'components/Inputs/TextInputV2'


export const CustomerAddreesLocation = (props) => {
  let delivery = { ...props.delivery }
  delivery = delivery.delivery

  let scope, scopeCountries, scopeProvinces, elements = []

  if (delivery) {
    scopeCountries = delivery.scopeCountries
    scopeProvinces = delivery.scopeProvinces
  }

  if (scopeCountries !== '' || scopeProvinces === '') {
    if (scopeCountries) {
      elements.push('countryCode')
      scopeCountries = scopeCountries.split(',')
    }

    if (scopeProvinces) {
      elements.push('province')
      scopeProvinces = scopeProvinces.split(',')
    }

    scope = {
      elements,
      countryCodeValues: scopeCountries,
      provinceValues: scopeProvinces,
    }
  }

  return (
    <InputGoogleMapGeo
      {...props}
      modalId={`google-geo-${props.id}`}
      location={props.value}
      requiredDatas={[/*'district', 'amphoe',*/ 'province', 'countryCode']}
      scope={scope}
    />
  )
}



export const CustomerGroup = (props) => {
  const lang = props.lang || 'local'
  const id = `group`
  const value = props.value || ''
  const { business } = props

  const options = [
    { value: '', name: 'เลือกกลุ่มลูกค้า'}
  ]

  business.customerGroups.map(g => {
    options.push({
      value: g.code,
      name: g.name[lang]
    })
  })

  return (
    <Select
      id={id}
      label="เลือกกลุ่มลูกค้า"
      placeholderDisabled
      value={value}
      options={options}
    />
  )
}

export const CustomerCode = (props) => {
  const { business, value, customer, onChange } = props
  const query =`businesses_id=${business._id}`

  let unselectId

  if (customer && customer._id) {
    unselectId = customer._id
  }

  return (
    <InputCheckDuplicate
      id="code"
      label="รหัสลูกค้า"
      type="code"
      value={value}
      params_business_id={business._id}
      action={checkCustomerDuplicate}
      useQuery={{
        authName: 'admin',
        dbNo: business.dbNo,
        businessType: business.type,
        businesses_id: business._id
      }}
      query={query}
      unselectId={unselectId}
      onChange={onChange}
    />
  )
}

export const CustomerFirstName = (props) => {
  const lang = props.lang || 'local'
  const id = `firstName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label={props.label || 'ชื่อ'}
      placeholder="ชื่อ"
      id={id}
      value={value}
      type="text"
      required
    />
  )
}

export const CustomerLastName = (props) => {
  const lang = props.lang || 'local'
  const id = `lastName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label={props.label || 'นามสกุล'}
      placeholder="นามสกุล"
      id={id}
      value={value}
      type="text"
      required
    />
  )
}



export const CustomerCorporate = (props) => {
  const customer = props.value
  const firstName = customer && customer.firstName
  const lastName = customer && customer.lastName
  const companyName = customer && customer.companyName
  const corporate = customer && customer.corporate

  const radios = [
    {
      value: 'false',
      label: 'บุคคลธรรมดา',
      child: <CustomerName {...props} firstName={firstName} lastName={lastName} />
    },
    {
      value: 'true',
      label: 'นิติบุคคล',
      child: <CustomerCompanyNameFull
                {...props}
                firstName={firstName}
                lastName={lastName}
                companyName={companyName}
             />
    }
  ]

  return (
    <RadioGroup
      required="true"
      childExternal
      label="ประเภทลูกค้า"
      id="corporate"
      value={corporate ? corporate.toString() : 'false'}
      style={{ marginTop: '-15px' }}
      radios={radios}
    />
  )
}


export const CustomerName = (props) => {
  return (
    <Row>
      <Column col="column col-xs-12 col-md-6 pd-right-10-md pd-right-10-lg pd-right-10-xlg">
        <CustomerFirstName {...props} value={props.firstName} />
      </Column>
      <Column col="column col-xs-12 col-md-6 pd-left-10-md pd-left-10-lg pd-left-10-xlg">
        <CustomerLastName {...props} value={props.lastName}  />
      </Column>
    </Row>
  )
}

export const CustomerCompanyNameFull = (props) => {
  return (
    <Row>
      <Column col="column col-xs-12">
        <CustomerCompanyName {...props} value={props.companyName} />
        <input type="hidden" data-id="firstName.local" value="" />
        <input type="hidden" data-id="lastName.local" value="" />
      </Column>
    </Row>
  )
}


export const CustomerCompanyName = (props) => {
  const lang = props.lang || 'local'
  const id = `companyName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ชื่อบริษัท"
      id={id}
      name={id}
      value={value}
      active
      placeholder="ชื่อบริษัท"
      inputType="text"
      type="text"
      required
      iconValid
    />
  )
}


export const CustomerTaxId = (props) => {
  return (
    <InputNo
      label="เลขบัตรประชาชน/เลขผู้เสียภาษี"
      id="taxId"
      value={props.value}
      placeholder="เลขบัตรประชาชน/เลขผู้เสียภาษี"
      digit={13}
      required={props.required}
      iconValid
    />
  )
}

export const CustomerLineId = (props) => {
  return (
    <TextInput
      label="ไลน์ไอดี"
      id="lineId"
      value={props.value}
      placeholder="ไลน์ไอดี"
      required={props.required}
      iconValid
    />
  )
}

export const CustomerTel = (props) => {
  return (
    <InputPhoneNumber
     label="โทรศัพท์"
     id="tel"
     hiddenCode
     value={props.value}
     iconValid />
  )
}

// export const CustomerAuthPhone = (props) => {
//   return (
//     <InputPhoneNumber
//      label="มือถือ"
//      id="authPhone"
//      hiddenCode
//      value={props.value}
//      required={props.required}
//      iconValid
//     />
//   )
// }


export const CustomerAuthPhone = (props) => {
  const { business, authName, customers_id } = props

  const fetchAction = (value, params) => {
    const query = {
      authName:  authName || 'admin',
      businesses_id: business._id
    }

    const match = {
      'authPhone.number': value,
    }

    if (customers_id) {
      match['_id'] = { $ne: customers_id }
    }

    return fetchQueryCustomerOne(query, {
      body: {
        match,
      },
      ...params
    })
  }

  if (props.unchecked) {
    return <TextInputV2
      label="หมายเลขโทรศัพท์"
      id="authPhone.number"
      name="authPhone"
      value={props.value}
      placeholder="หมายเลขโทรศัพท์"
      inputType="tel"
      type="phoneNumber-10"
      required
    />
  } else {
    return useMemo(() => <TextInputFetch
      label="หมายเลขโทรศัพท์"
      id="authPhone.number"
      name="authPhone"
      fetchAction={fetchAction}
      getFetchData={props.getFetchData}
      value={props.value}
      active
      placeholder="หมายเลขโทรศัพท์"
      inputType="tel"
      type="phoneNumber-10"
      fetchMsg={{
        success: "หมายเลขโทรศัพท์นี้ เป็นสมาชิกอยู่แล้ว",
      }}
      required
    />, [
      props.value
    ])
  }
}

export const CustomerEmail = (props) => {
  return (
    <TextInput
      label="อีเมล"
      id="email"
      name="email"
      value={props.value}
      active
      placeholder="อีเมล"
      inputType="email"
      type="email"
      msgError="รูปแบบอีเมลไม่ถูกต้อง"
      iconValid />
  )
}

export const CustomerBranchName = (props) => {
  const lang = props.lang || 'local'
  const id = `branchName.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ชื่อสาขา"
      placeholder="ชื่อสาขา"
      id={id}
      name={id}
      value={value}
      required={props.required}
      iconValid
    />
  )
}

export const CustomerBranchNo = (props) => {
  const id = `branchNo`
  const value = props.value || ''

  return (
    <InputNo
      label="เลขที่สาขา"
      id={id}
      name={id}
      value={value}
      placeholder="เลขที่สาขา"
      required={props.required}
      iconValid
    />
  )
}

export const CustomerAddress = (props) => {
  const lang = props.lang || 'local'
  const id = `address.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ที่อยู่"
      placeholder="ที่อยู่"
      id={id}
      name={id}
      value={value}
      required={props.required}
      iconValid
    />
  )
}

export const CustomerAddressNo = (props) => {
  const lang = props.lang || 'local'
  const id = `address.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ที่อยู่"
      placeholder="ที่อยู่"
      id={id}
      name={id}
      value={value}
      required
      iconValid
    />
  )
}

export const CustomerAddressName = (props) => {
  const lang = props.lang || 'local'
  const id = `name.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ชื่อ - นามสกุล"
      placeholder="ชื่อ - นามสกุล"
      id={id}
      name={id}
      value={value}
      required
      iconValid
    />
  )
}

export const CustomerAddressTel = (props) => {
  return (
    <InputPhoneNumber
     label="เบอร์โทร"
     id="tel"
     value={props.value}
     required
     iconValid
    />
  )
}

export const CustomerStreetAddress = (props) => {
  const lang = props.lang || 'local'
  const id = `streetAddress.${lang}`
  const value = props.value ? props.value[lang] : ''

  return (
    <TextInput
      label="ที่อยู่"
      placeholder="ที่อยู่"
      id={id}
      name={id}
      value={value}
      required
      iconValid
    />
  )
}

export const CustomerAddressGeo = (props) => {
  const id = `geo`
  const value = props.value || {}

  return (
    <InputAddress
      id={id}
      value={value}
      onChange={props.onChange}
    />
  )
}

export const CustomerRewardPoints = (props) => {
  const id = `rewardPoints`
  const value = props.value || 0

  return (
    <InputDecimal
      required
      label="แต้มสะสม"
      id={id}
      decimal="2"
      value={value}
      iconValid
    />
  )
}

