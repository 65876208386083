import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import ShippingsMenuContainer from 'delivery/modules/Shippings/containers/ShippingsMenuContainer'

// Components
const ShippingsListContainer = lazy(() => import('delivery/modules/Shippings/containers/ShippingsListContainer'))
const ShippingManagePage = lazy(() => import('delivery/modules/Shippings/pages/ShippingManagePage'))
const status = "all|ready|checkin|received|prepare-shipping|shipping|shipping-success|destination-branch|delivery|failed|successed"

const ShippingsMenuRoutes = [
  {
    path: '/:username/shippings',
    component: ShippingsMenuContainer,
    routes: [
      {
        path: `/:username/shippings/:status(${status})/:Shippings_id`,
        component: suspenseComponent(ShippingsListContainer),
        routes: [
          {
            path: `/:username/shippings/:status(${status})/:Shippings_id`,
            auth: { reqAuthBiz: true },
            component: suspenseComponent(ShippingManagePage),
          }
        ]
      },
    ]
  }
]

export default ShippingsMenuRoutes
