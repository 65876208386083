import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import ParcelDestinationMenuContainer from 'delivery/modules/ParcelDestination/containers/ParcelDestinationMenuContainer'

// Components
const ParcelDestinationListContainer = lazy(() => import('delivery/modules/ParcelDestination/containers/ParcelDestinationListContainer'))
const ParcelDestinationManagePage = lazy(() => import('client/projects/delivery/modules/ParcelDestination/pages/ParcelDestinationManagePage'))

const status = "all|ready|checkin|received|prepare-shipping|shipping|shipping-success|destination-branch|undelivery|delivery|failed|successed"

const ParcelDestinationMenuRoutes = [
  {
    path: '/:username/parcel-destination',
    component: ParcelDestinationMenuContainer,
    routes: [
      {
        path: `/:username/parcel-destination/:status(${status})/:parcels_id`,
        component: suspenseComponent(ParcelDestinationListContainer),
        routes: [
          {
            path: `/:username/parcel-destination/:status(${status})/:parcels_id`,
            auth: { reqAuthBiz: true },
            component: suspenseComponent(ParcelDestinationManagePage),
          }
        ]
      },
    ]
  }
]

export default ParcelDestinationMenuRoutes
