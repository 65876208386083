import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components Global
import ProfileAvatar from 'client/components/ProfileAvatar'
import FetchData from 'client/components/FetchData'
import PreLoading from 'client/components/PreLoading'
import { BtnModalOpen } from 'client/components/Modals/BtnModal'
import { Row, Column } from 'client/components/UserInterface'
import { TextMiddle } from 'client/components/Text'

// Components Local
import BusinessCreateModal from '../../components/BusinessCreateModal'

// Redux Actions
import { fetchBusinessesByAdmin } from 'client/core/redux/Businesses/BusinessesActions'

// Value
import { businessValue } from 'client/core/redux/Businesses/BusinessesValues'



const BusinessesList = (props) => {
  useEffect(() => {
    fetchBusinessesForAdmin(props.auth)
  }, [props.auth])

  const fetchBusinessesForAdmin = (auth) => {
    if (auth) {
      if (auth.user && auth.user !== 'unauthorized') {
        props.dispatch(fetchBusinessesByAdmin({
          loadId: 'listBusinesses'
        }))
      }
    }
  }

  const title = "ระบบจัดการธุรกิจ"
  const { businesses, auth } = props
  const { user } = auth

  return (
    <div>
      <BusinessCreateModal {...props} />

      <PreLoading theme="dark" id="listBusinesses">
        <FetchData
          theme="dark"
          length={businesses.length}
          icon="business"
          msg={`ไม่มี${title} <br />`}
          modalId="businessCreateModal"
          btnText={`สร้าง${title}`}
        >

          <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

            { user.role === 'admin' &&
              <Column col="column col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xlg-2">
                <div style={{ margin: '0 7px 14px' }}>
                  <TextMiddle height="230px" width="100%">
                    <BtnModalOpen id="businessCreateModal">
                      <TextMiddle center className="circle hover-text-white cursor-point" height="150px" width="150px" align="center" style={{ borderStyle: 'dashed' }}>
                        <i className="material-icons" style={{ fontSize: '3rem'}}>add</i><br />เพิ่มธุรกิจ
                      </TextMiddle>
                    </BtnModalOpen>
                  </TextMiddle>
                </div>
              </Column>
            }

            { businesses.map((business, key) => {
              business = businessValue({ business })

              return business && (
                <Column key={key} col="column col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xlg-2">
                  <div
                    className="mg-right-7 mg-left-7 mg-bottom-14 pd-20 center border-radius-3 box-shadow-2"
                    style={{ background: '#212527' }}>

                    <div className="height-100 width-100 border-3 border-fff border-radius-circle mg-bottom-10 dp-inline-block">
                      <Link to={`/${business.username}`}>
                        <ProfileAvatar
                          circle
                          images={business.images}
                          imageSize="m"
                          color={business.avatarColor}
                          texts={[business.nameLang]}
                          textSize="2rem"
                        />
                      </Link>
                    </div>

                    <div className="font-1-0 height-20 line-height-20 color-c0c0c0 text-ellipsis">
                      { business.nameLang }
                    </div>
                    <div className="font-0-9 height-20 line-height-20 color-c0c0c0 text-ellipsis">
                      { business.branchNameLang || '-' }
                    </div>

                    <Link to={`/${business.username}`} className="btn height-30 line-height-30 mg-top-10 font-0-9 grey darken-3">
                      <i className="material-icons left">settings</i> ไปยังระบบจัดการ
                    </Link>
                  </div>
                </Column>
              )
            }) }
          </Row>
        </FetchData>
      </PreLoading>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    businesses: state.businesses.data
  }
}

export default connect(mapStateToProps)(BusinessesList)
