import React from 'react'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// imgs
import titleIcon from 'delivery/modules/Businesses/resources/icons/settings.png'


const menuItems = ({ params }) => {
  return [
    {
      name: 'ตั้งค่าสิทธิการใช้งาน',
      title: true
    },
    {
      name: 'กลุ่มสิทธิ',
      icon: 'groups',
      link: `/${params.username}/settings/admin-role-groups`
    },
    {
      name: 'ระบบจัดการ',
      icon: 'manage_accounts',
      link: `/${params.username}/settings/systems`
    },

    {
      name: 'งานเอกสาร',
      title: true
    },
    {
      name: 'การรันเลขเอกสาร',
      icon: 'filter_9_plus',
      link: `/${params.username}/settings/running-numbers`
    },

    {
      name: 'ตั้งค่าระบบ',
      title: true
    },
    {
      name: 'ค่าบริการจัดส่ง',
      icon: 'payments',
      link: `/${params.username}/settings/delivery-fee`
    },
    {
      name: 'พร้อมเพย์',
      icon: 'credit_card',
      link: `/${params.username}/settings/prompt-pay`
    },

    // {
    //   name: 'กลุ่ม',
    //   title: true
    // },
    // {
    //   name: 'กลุ่มลูกค้า',
    //   nameShort: 'กลุ่มลูกค้า',
    //   icon: 'group_add',
    //   link: `/${params.username}/settings/groups/customer`
    // }
  ]
}

const SettingsMenuContainer = (props) => {
  const { business, setting, location, route, match, windowModal, closeWindowModal, history } = props
  const params = match.params

  return (
    <Window
      title="ตั้งค่าระบบ"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive items={menuItems({ params, business, setting })} />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Layouts2>

      </LayoutsTwo>
    </Window>
  )
}

export default SettingsMenuContainer
