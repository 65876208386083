// Containers
import ParcelDestinationContainer from 'delivery/modules/ParcelDestination/containers/ParcelDestinationContainer'

// Routes
import ParcelDestinationMenuRoutes from './routes/ParcelDestinationMenuRoutes'

const ParcelDestinationRoutes = [
  {
    path: '/:username/parcel-destination',
    component: ParcelDestinationContainer,
    routes: [
      ...ParcelDestinationMenuRoutes
    ]
  }
]

export default ParcelDestinationRoutes
