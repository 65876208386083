import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import renderRoutes from 'client/core/routes/renderRoutes'


// Components Global
import PageError from 'client/components/PageError'
import PreLoading from 'client/components/PreLoading'

// Redux Actions
import { fetchBusinessStart } from 'client/core/redux/Businesses/BusinessesActions'
import { businessValue } from 'client/core/redux/Businesses/BusinessesValues'
import { settingValue } from '../../../../redux/Settings/SettingsValues'


const BusinessContainer = (props) => {
  const { match, dispatch, route, location } = props
  const { username } = match.params
  const business = businessValue({ business: props.business })
  const setting = settingValue({ setting: props.setting })

  useEffect(() => {
    dispatch(fetchBusinessStart(username, {
      loadId: 'businessLoading'
    }))
  }, [])

  return (
    <PreLoading theme="dark" id="businessLoading">
      { (business && setting) ?
        <Fragment>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting },
            location
          }) }
        </Fragment>
      :
        <PageError code="404" />
      }
    </PreLoading>
  )
}


function mapStateToProps(store, props) {
  return {
    business: store.businesses.data.filter(business => business.username === props.match.params.username)[0],
    setting: store.settings.data[0],
  }
}

export default connect(mapStateToProps)(BusinessContainer)
