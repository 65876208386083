import React from 'react'
import renderRoutes from 'core/routes/renderRoutes'
import WindowDefault from 'components/Windows/WindowDefault'

const BusinessSettingsContainer = (props) => {
  const { route, business, setting, location, windowModal, closeWindowModal } = props

  return (
    <WindowDefault windowModal={windowModal}>
      { renderRoutes({
        routes: route.routes,
        extraProps: { business, setting, windowModal, closeWindowModal },
        location
      }) }
    </WindowDefault>
  )
}

export default BusinessSettingsContainer
