import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import CustomersMenuContainer from 'delivery/modules/Customers/containers/CustomersMenuContainer'

// Components
const CustomersListContainer = lazy(() => import('delivery/modules/Customers/containers/CustomersListContainer'))
const CustomerManagePage = lazy(() => import('delivery/modules/Customers/pages/CustomerManagePage'))


const CustomersMenuRoutes = [
  {
    path: '/:username/customers',
    component: CustomersMenuContainer,
    routes: [
      {
        path: '/:username/customers/:status(all|trash)/:customers_id',
        component: suspenseComponent(CustomersListContainer),
        routes: [
          {
            path: '/:username/customers/:status(all|trash)/:customers_id',
            auth: { reqAuthBiz: true },
            component: suspenseComponent(CustomerManagePage),
          }
        ]
      },
    ]
  }
]

export default CustomersMenuRoutes
