// Containers
import ShippingsContainer from 'delivery/modules/Shippings/containers/ShippingsContainer'

// Routes
import ShippingsMenuRoutes from './routes/ShippingsMenuRoutes'

const ShippingsRoutes = [
  {
    path: '/:username/shippings',
    component: ShippingsContainer,
    routes: [
      ...ShippingsMenuRoutes
    ]
  }
]

export default ShippingsRoutes
