import { lazy } from 'react'
import suspenseComponent from 'util/suspenseComponent'

// Containers
import SettingsMenuContainer from 'delivery/modules/Settings/containers/SettingsMenuContainer'

// Pages
const AdminRoleGroupsSettingPage = lazy(() => import('delivery/modules/Settings/pages/AdminRoleGroupsSettingPage'))
const SystemSettingPage = lazy(() => import('delivery/modules/Settings/pages/SystemSettingPage'))
const RunningNumbersSettingPage = lazy(() => import('delivery/modules/Settings/pages/RunningNumbersSettingPage'))
const DeliveryFeeSettingPage = lazy(() => import('delivery/modules/Settings/pages/DeliveryFeeSettingPage'))
const PromptPaySettingPage = lazy(() => import('delivery/modules/Settings/pages/PromptPaySettingPage'))

const SettingsMenuRoutes = [
  {
    path: '/:username/settings',
    component: SettingsMenuContainer,
    routes: [
      {
        path: '/:username/settings/admin-role-groups',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(AdminRoleGroupsSettingPage)
      },
      {
        path: '/:username/settings/systems',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(SystemSettingPage)
      },
      {
        path: '/:username/settings/running-numbers',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(RunningNumbersSettingPage)
      },

      {
        path: '/:username/settings/delivery-fee',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(DeliveryFeeSettingPage)
      },
      {
        path: '/:username/settings/prompt-pay',
        exact: true,
        auth: { reqAuthBiz: true, reqExpDate: true },
        component: suspenseComponent(PromptPaySettingPage)
      },
    ]
  }
]





export default SettingsMenuRoutes
