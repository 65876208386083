import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import ParcelsMenuContainer from 'delivery/modules/Parcels/containers/ParcelsMenuContainer'

// Components
const ParcelsListContainer = lazy(() => import('delivery/modules/Parcels/containers/ParcelsListContainer'))
const ParcelManagePage = lazy(() => import('delivery/modules/Parcels/pages/ParcelManagePage'))


const ParcelInfoPage = lazy(() => import('delivery/modules/Parcels/pages/ParcelInfoPage'))
const ParcelHistoriesPage = lazy(() => import('client/projects/delivery/modules/Parcels/pages/ParcelHistoriesPage'))
const ParcelSignaturePage = lazy(() => import('delivery/modules/Parcels/pages/ParcelSignaturePage'))
const ParcelTakePhotoPage = lazy(() => import('delivery/modules/Parcels/pages/ParcelTakePhotoPage'))

const status = "all|ready|checkin|received|prepare-shipping|shipping|shipping-success|destination-branch|delivery|failed|successed"

const ParcelsMenuRoutes = [
  {
    path: '/:username/parcels',
    component: ParcelsMenuContainer,
    routes: [
      {
        path: `/:username/parcels/:status(${status})/:parcels_id/:detail_path?`,
        component: suspenseComponent(ParcelsListContainer),
        routes: [
          {
            path: `/:username/parcels/:status(${status})/:parcels_id/:detail_path(info|signature|take-photo|histories)`,
            auth: { reqAuthBiz: true },
            component: suspenseComponent(ParcelManagePage),
            routes: [
              {
                path: `/:username/parcels/:status(${status})/:parcels_id/:detail_path(info)`,
                auth: { reqAuthBiz: true },
                component: suspenseComponent(ParcelInfoPage),
              },
              {
                path: `/:username/parcels/:status(${status})/:parcels_id/:detail_path(signature)`,
                auth: { reqAuthBiz: true },
                component: suspenseComponent(ParcelSignaturePage),
              },
              {
                path: `/:username/parcels/:status(${status})/:parcels_id/:detail_path(take-photo)`,
                auth: { reqAuthBiz: true },
                component: suspenseComponent(ParcelTakePhotoPage),
              },
              {
                path: `/:username/parcels/:status(${status})/:parcels_id/:detail_path(histories)`,
                auth: { reqAuthBiz: true },
                component: suspenseComponent(ParcelHistoriesPage),
              },
            ]
          }
        ]
      },
    ]
  }
]

export default ParcelsMenuRoutes
