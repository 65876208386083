import { getDate } from 'utils/getDateTime'
import React from 'react'

// Components Global
import DatasList from 'components/DatasList'

// Values
import { customerValue } from 'delivery/redux/Customers/CustomersValues'


const CustomerContact = (props) => {
  const { customer, format } = props
  const { addressLang, telFull,  authPhoneFull }  = customerValue({ customer })

  return (
    <div>
      <div>
        { customer &&
          <div>
            { format === 'table' &&
              <table>
                <tbody>
                  <tr className="font-0-9">
                    <td height="25" width="130" className="pd-0">เบอร์โทร</td>
                    <td width="20" className="pd-0 center">:</td>
                    <td className="pd-0 pd-left-10">{authPhoneFull}</td>
                  </tr>

                  <tr className="font-0-9">
                    <td height="25" className="pd-0">อีเมล</td>
                    <td className="pd-0 center">:</td>
                    <td className="pd-0 pd-left-10">{customer.email ? customer.email : '-'}</td>
                  </tr>

                  <tr className="font-0-9">
                    <td height="25" className="pd-0">ที่อยู่</td>
                    <td className="pd-0 center">:</td>
                    <td className="pd-0 pd-left-10">{ addressLang ? addressLang : '-'}</td>
                  </tr>

                  <tr className="font-0-9">
                    <td height="25" className="pd-0">เลขประจำตัวผู้เสียภาษี</td>
                    <td className="pd-0 center">:</td>
                    <td className="pd-0 pd-left-10">{customer.taxId ? customer.taxId : '-'}</td>
                  </tr>
                </tbody>
              </table>
            }

            { format === 'icon' &&
              <DatasList
                borderLastNone
                style={{ border: '0' }}
                items={[
                  {
                    label: 'วันที่สมัคร',
                    value: getDate(customer.created, 'DD/MM/YYYY', 'th'),
                    icon: 'event'
                  },
                  {
                    label: 'ชื่อผู้ติดต่อ',
                    value: customer.contactNameLang || '-',
                    icon: 'person',
                    hidden: !customer.corporate
                  },
                  {
                    label: 'มือถือ',
                    value: authPhoneFull ? authPhoneFull : '-',
                    icon: 'phone_iphone'
                  },
                  {
                    label: 'อีเมล',
                    value: customer.email ? customer.email : '-',
                    icon: 'email',
                    hidden: !customer.email
                  },
                  {
                    label: 'ไลน์ไอดี',
                    value: customer.lineId ? customer.lineId : '-',
                    iconFa: 'fab fa-line',
                    hidden: !customer.lineId
                  },
                  {
                    label: 'ที่อยู่',
                    value: addressLang ? addressLang : '-',
                    icon: 'location_on',
                    hidden: !customer.addressLang
                  },
                  {
                    label: 'เลขประจำตัวผู้เสียภาษี',
                    value: customer.taxId ? customer.taxId : '-',
                    icon: 'confirmation_number',
                    hidden: !customer.taxId
                  },
                ]}
              />
            }

            { format === 'descriptions' &&
              <div>
                { (addressLang || telFull || mobileFull || customer.taxId) ?
                  <div className="pd-10 bg-efefef border-radius-5 font-0-9">
                    { addressLang && <div>ที่อยู่ {addressLang}</div> }
                    { authPhoneFull && <div>มือถือ {authPhoneFull}</div> }
                    { customer.taxId && <div>เลขประจำตัวผู้เสียภาษี {customer.taxId}</div> }
                  </div>
                :
                  <div className="pd-10 bg-efefef center border-radius-5 font-0-9">
                    <span>ไม่มีข้อมูลติดต่อ</span>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default CustomerContact
