import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import RubberReportsMenuContainer from 'client/projects/delivery/modules/Reports/containers/ReportsMenuContainer'

// Pages
const ReportOrdersPage = lazy(() => import('delivery/modules/Reports/pages/ReportOrdersPage'))
const ReportOrdersParcelsPage = lazy(() => import('delivery/modules/Reports/pages/ReportOrdersParcelsPage'))
const ReportOrdersParcelsPaymentPage = lazy(() => import('delivery/modules/Reports/pages/ReportOrdersParcelsPaymentPage'))




const ReportsMenuRoutes = [
  {
    path: '/:username/reports',
    component: RubberReportsMenuContainer,
    routes: [
      {
        path: `/:username/reports/orders`,
        component: suspenseComponent(ReportOrdersPage),
      },
      {
        path: `/:username/reports/parcels`,
        component: suspenseComponent(ReportOrdersParcelsPage),
      },
      {
        path: `/:username/reports/parcels-payment`,
        component: suspenseComponent(ReportOrdersParcelsPaymentPage),
      }
    ]
  }
]

export default ReportsMenuRoutes
