import { lazy } from 'react'
import suspenseComponent from 'util/suspenseComponent'

// Containers
import BusinessSettingsMenuContainer from 'core/modules/BusinessSettings/containers/BusinessSettingsMenuContainer'

// Pages
const Descriptions = lazy(() => import('core/modules/BusinessSettings/pages/Descriptions'))
const LanguagesPage = lazy(() => import('core/modules/BusinessSettings/pages/LanguagesPage'))
const Company = lazy(() => import('core/modules/BusinessSettings/pages/Company'))
const Tax = lazy(() => import('core/modules/BusinessSettings/pages/Tax'))
const PicturesPage = lazy(() => import('core/modules/BusinessSettings/pages/Pictures'))

const MessagingEmail = lazy(() => import('core/modules/BusinessSettings/pages/MessagingEmail'))
const MessagingLineNotify = lazy(() => import('core/modules/BusinessSettings/pages/MessagingLineNotify'))
const MessagingFacebook = lazy(() => import('core/modules/BusinessSettings/pages/MessagingFacebook'))
const NotificationsListPage = lazy(() => import('core/modules/BusinessSettings/pages/NotificationsListPage'))

const CustomerGroupdListPage = lazy(() => import('core/modules/BusinessSettings/pages/CustomerGroupsListPage'))
const AdminGroupsListPage = lazy(() => import('core/modules/BusinessSettings/pages/AdminGroupsListPage'))


const BusinessSettingsMenuRoutes = [
  {
    path: '/:username/business-settings',
    component: BusinessSettingsMenuContainer,
    routes: [
      {
        path: '/:username/business-settings',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(Descriptions)
      },
      {
        path: '/:username/business-settings/languages',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(LanguagesPage)
      },
      {
        path: '/:username/business-settings/company',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(Company)
      },
      {
        path: '/:username/business-settings/tax',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(Tax)
      },
      {
        path: '/:username/business-settings/pictures',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(PicturesPage)
      },




      {
        path: '/:username/business-settings/api/email',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(MessagingEmail)
      },
      {
        path: '/:username/business-settings/api/line-notify',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(MessagingLineNotify)
      },
      {
        path: '/:username/business-settings/api/facebook',
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(MessagingFacebook)
      },

      {
        path: `/:username/business-settings/notifications/:typeNotification(email|facebook|line-notify)`,
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(NotificationsListPage)
      },

      {
        path: `/:username/business-settings/groups/customer`,
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(CustomerGroupdListPage)
      },
      {
        path: `/:username/business-settings/groups/admin`,
        exact: true,
        auth: { reqAuthBiz: true },
        component: suspenseComponent(AdminGroupsListPage)
      },
    ]
  }
]





export default BusinessSettingsMenuRoutes
