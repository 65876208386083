import React, { Fragment, useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import config from 'delivery/config'
import renderRoutes from 'core/routes/renderRoutes'
import "regenerator-runtime/runtime"

// Components Global
import { Row, Column } from 'components/Columns'
import ProfileAvatar from 'components/ProfileAvatar'

import { getRoleSystemsAll } from 'core/functions/getAdminRole'

// css
import css from './css/businessDashboard.css'


const CDN_URL = config.app.cdnUrl
const s3Url = config.app.s3.url

const BusinessDashboardContainer = (props) => {
  const [open, setOpen] = useState(true)
  const { business, setting, match, route, location, auth } = props

  const closeWindowModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (location.pathname === `/${business.username}`) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [location.pathname])


  let image = `${CDN_URL}/common/imgs/cover.png`
  const cover = business && business.images.filter(image => image.album === 'cover')[0]

  if (cover && cover.paths && cover.paths.o) {
    image = `${s3Url}/${cover.paths.o}`
  }

  const systems = getRoleSystemsAll({ business, setting, auth, newV: true })


  const summaries = [
    {
      label: 'จำนวน',
      label2: 'ออเดอร์ประจำวันนี้',
      code: 'orders',
      icon: 'receipt_long',
      value: 100
    },
    {
      label: 'จำนวน',
      label2: 'พัสดุประจำวันนี้',
      code: 'parcels',
      icon: 'archive',
      value: 150
    },
    {
      label: 'จำนวน',
      label2: 'รายได้ประจำวัน',
      code: 'paidAmount',
      icon: 'credit_card',
      value: 2000
    },
    {
      label: 'จำนวน',
      label2: 'พัสดุที่ยังไม่รับ',
      code: 'shippingSuccess',
      icon: 'playlist_remove',
      value: 2000
    },
    {
      label: 'จำนวน',
      label2: 'พัสดุที่ยังไม่ส่ง',
      code: 'undelivery',
      icon: 'content_paste_off',
      value: 2000
    },
    {
      label: 'จำนวน',
      label2: 'พัสดุที่ส่งสำเร็จประจำวัน',
      code: 'successed',
      icon: 'verified',
      value: 2000
    },
  ]



  return (
    <Fragment>
      <Helmet title={business.nameLang} />

      <div className={`z-depth-1 ${css.cover} min-height-230`}>
        <div
          className={css.bg}
          style={{
            background: `url('${image}') no-repeat center center`,
            backgroundSize: '100% 100%'
          }}>

          <div className={css.content}>
            <table>
              <tbody>
                <tr>
                  <td className="width-160 center height-230">
                    <div className={`dp-inline-block width-100 height-100 border-3 border-fff border-radius-circle`}>
                      <ProfileAvatar
                        circle
                        images={business.images}
                        imageSize="m"
                        color={business.avatarColor}
                        texts={[business.nameLang]}
                        textSize="2rem"
                      />
                    </div>

                    <div className="center hidden-md hidden-lg hidden-xlg">
                      <div className="font-1-5 color-fff">{business.nameLang}</div>
                      <div className="font-1-2 color-fff">{business.branchNameLang}</div>
                    </div>
                  </td>
                  <td className="hidden-xs hidden-sm">
                    <div>
                      <div className="font-1-5 color-fff">{business.nameLang}</div>
                      <div className="font-1-2 color-fff">{business.branchNameLang}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/*<div className={`mg-top-50 width-full`}>
        <Row>
          { summaries.map(summary => {
              return (
                <Column key={summary.code} col="column col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xlg-2">
                  <div className={`z-depth-1 mg-5 pd-20 border-radius-20`} style={{ background: '#212527' }}>
                    <i className="material-icons font-3-0 color-fff">{summary.icon}</i>

                    <div className="mg-top-10 color-fff font-0-8">{summary.label}</div>
                    <div className="color-fff color-fff font-0-9">{summary.label2}</div>
                    <div className="mg-top-10 color-fff font-1-5">{summary.value}</div>
                  </div>
                </Column>
              )
            })
          }
        </Row>
        </div>*/}

      <div className={`${css.boxSystem} mg-top-50`}>
        <div className="mg-bottom-20 color-fff center">
          <span className="font-1-5">ระบบจัดการขนส่ง</span>
          <p className="font-0-9">เริ่มต้นใช้งานโดยการเลือกระบบที่ต้องการจากด้านล่าง</p>
        </div>

        <Row>
          { systems.map((system, key) => {
              return (
                <Column key={key} col="column col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xlg-2">
                  <div className={`z-depth-1 mg-5 pd-10 ${css.itemSystem}`}>
                    <Link to={`/${match.params.username + system.link}`}>
                      <div className={`${css.boxIcon} width-120 height-120 border-2 border-fff pd-inline-block circle`}>
                        <img src={require(`../../resources/icons/${system.id}.png`)} className="width-60" />
                      </div>
                      <div className="mg-top-10 color-fff font-1-0">{system.name}</div>
                    </Link>
                  </div>
                </Column>
              )
            })
          }
        </Row>

        <div className="modal-jsx" style={{ visibility: open && 'visible', opacity: open && '1', top: open && '0' }}>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business, setting, windowModal: open, closeWindowModal },
            location
          }) }
        </div>
      </div>

      <style jsx>{`
        .modal-jsx {
          position: fixed;
          top: 100%;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 9999;
          transition: all 0.8s;
          opacity: 1;
          visibility: hidden;
        }
      `}</style>
    </Fragment>
  )
}

function mapStateToProps(store, props) {
  return {
    app: store.app,
    auth: store.auth
  }
}

export default connect(mapStateToProps)(BusinessDashboardContainer)
