import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

// Import Actions
import { signOut } from 'client/core/redux/Auth/AuthActions'

class SignOut extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.props.dispatch(signOut({
      loadId: 'signout',
      toastMsg: 'ออกจากระบบแล้ว',
      props: this.props,
      redirect: { url: '/signin' }
    }))
  }

  render() {
    return <div></div>
  }
}

export default withRouter(connect()(SignOut))
