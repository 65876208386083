import { lazy } from 'react'
import suspenseComponent from 'utils/suspenseComponent'

// Containers
import OrdersMenuContainer from 'delivery/modules/Orders/containers/OrdersMenuContainer'
import OrdersListContainer from 'delivery/modules/Orders/containers/OrdersListContainer'

// Pages
const OrderManagePage = lazy(() => import('delivery/modules/Orders/pages/OrderManagePage'))

const status = ':status(all|today|draft|no-address|have-address|completed|canceled)'


const OrdersMenuRoutes = [
  {
    path: '/:username/orders',
    component: OrdersMenuContainer,
    routes: [
      {
        path: `/:username/orders/${status}/:orders_id`,
        component: suspenseComponent(OrdersListContainer),
        params: { forUser: false },
        routes: [
          {
            path: `/:username/orders/${status}/:orders_id`,
            exact: true,
            auth: { reqAuthBiz: true },
            component: suspenseComponent(OrderManagePage)
          }
        ]
      }
      // {
      //   path: `/:username/orders/reports`,
      //   auth: { reqAuthBiz: true, reqExpDate: true },
      //   component: suspenseComponent(OrdersReportPage)
      // }
    ]
  }
]

export default OrdersMenuRoutes
