// Containers
import ParcelsContainer from 'delivery/modules/Parcels/containers/ParcelsContainer'

// Routes
import ParcelsMenuRoutes from './routes/ParcelsMenuRoutes'

const ParcelsRoutes = [
  {
    path: '/:username/parcels',
    component: ParcelsContainer,
    routes: [
      ...ParcelsMenuRoutes
    ]
  }
]

export default ParcelsRoutes
