// Containers
import CustomersContainer from 'delivery/modules/Customers/containers/CustomersContainer'

// Routes
import CustomersMenuRoutes from './routes/CustomersMenuRoutes'

const CustomersRoutes = [
  {
    path: '/:username/customers',
    component: CustomersContainer,
    routes: [
      ...CustomersMenuRoutes
    ]
  }
]

export default CustomersRoutes
