import React from 'react'
import renderRoutes from 'core/routes/renderRoutes'

// Components Global
import Window from 'components/Windows/Window'
import { LayoutsTwo, Layouts1, Layouts2 } from 'components/LayoutsTwoResponsive'
import MenuSidebarResponsive from 'components/MenuSidebarResponsive'

// imgs
import titleIcon from '../../Businesses/resources/icons/parcels.png'


const menuItems = (params) => {
  let path = `/${params.username}/parcels`

  return [
    {
      name: 'รายการ',
      title: true
    },
    {
      name: 'พัสดุทั้งหมด',
      icon: 'public',
      link: `${path}/all/all`,
      searchLinkActive: `${path}/all/`,
    },
    {
      name: 'แยกตามสถานะ',
      title: true
    },

    /*{
      name: 'พร้อมนำส่ง',
      icon: 'archive',
      link: `${path}/ready/all`,
      searchLinkActive: `${path}/ready/`
    },*/
    {
      name: 'ส่งพัสดุที่สาขา',
      icon: 'location_city',
      link: `${path}/checkin/all`,
      searchLinkActive: `${path}/checkin/`
    },
    // {
    //   name: 'เข้ารับพัสดุแล้ว',
    //   icon: 'real_estate_agent',
    //   link: `${path}/received/all`,
    //   searchLinkActive: `${path}/received/`
    // },
    {
      name: 'เตรียมขนส่ง',
      icon: 'pending_actions',
      link: `${path}/prepare-shipping/all`,
      searchLinkActive: `${path}/prepare-shipping/`
    },
    {
      name: 'กำลังขนส่ง',
      icon: 'local_shipping',
      link: `${path}/shipping/all`,
      searchLinkActive: `${path}/shipping/`
    },
    {
      name: 'ขนส่งสำเร็จ',
      icon: 'location_on',
      link: `${path}/shipping-success/all`,
      searchLinkActive: `${path}/shipping-success/`
    },
    {
      name: 'ถึงสาขาปลายทาง',
      icon: 'where_to_vote',
      link: `${path}/destination-branch/all`,
      searchLinkActive: `${path}/destination-branch/`
    },
    // {
    //   name: 'กำลังจัดส่ง',
    //   icon: 'directions_car',
    //   link: `${path}/delivery/all`,
    //   searchLinkActive: `${path}/delivery/`
    // },
    {
      name: 'ส่งไม่สำเร็จ',
      icon: 'car_crash',
      link: `${path}/failed/all`,
      searchLinkActive: `${path}/failed/`
    },
    {
      name: 'ส่งสำเร็จ',
      icon: 'verified',
      link: `${path}/successed/all`,
      searchLinkActive: `${path}/successed/`
    }
  ]
}


const ParcelsMenuContainer = (props) => {
  const { business, location, route, match, history, windowModal, closeWindowModal } = props

  return (
    <Window
      title="จัดการพัสดุ"
      titleIcon={titleIcon}
      windowModal={windowModal}
      closeWindowModal={closeWindowModal}
      history={history}
      linkClose={`/${match.params.username}`}>

      <LayoutsTwo>
        <Layouts1>
          <MenuSidebarResponsive
            match={match}
            items={menuItems(match.params)}
          />
        </Layouts1>

        <Layouts2 hideScrollbars>
          { renderRoutes({
            routes: route.routes,
            extraProps: { business },
            location
          }) }
        </Layouts2>
      </LayoutsTwo>
    </Window>
  )
}

export default ParcelsMenuContainer
