export const settingValue = ({ setting, lang='local' }) => {
  if (setting) {

    return {
      ...setting,
    }
  }
}



export const getDefaultSystemsAll = () => {
  let systemsAll = [
    {
      id: 'reports',
      name: 'รายงาน',
      link: '/reports/orders'
    },
    {
      id: 'orders',
      name: 'จัดการออเดอร์',
      link: '/orders/all/all'
    },
    {
      id: 'parcels',
      name: 'จัดการพัสดุ',
      link: '/parcels/all/all'
    },
    {
      id: 'shippings',
      name: 'จัดการการขนส่ง',
      link: '/shippings/all/all'
    },
    {
      id: 'parcel-destination',
      name: 'พัสดุปลายทาง',
      link: '/parcel-destination/all/all'
    },
    {
      id: 'customers',
      name: 'รายชื่อลูกค้า',
      link: '/customers/all/all'
    },
    {
      id: 'admins',
      name: 'ผู้ดูและบบ',
      link: '/admins/all/all'
    },
    {
      id: 'users',
      name: 'ผู้ใช้งาน',
      link: '/users/all/all'
    },
    {
      id: 'business-settings',
      name: 'จัดการข้อมูลธุรกิจ',
      link: '/business-settings'
    },
    {
      id: 'settings',
      name: 'ตั้งค่าระบบ',
      link: '/settings/admin-role-groups'
    }
  ]

  return systemsAll
}


export const systemsList = ({ setting }) => {
  let systemsAll = [
    {
      id: 'orders',
      name: 'จัดการออเดอร์',
      link: '/orders/all/all',
      status: true
    },
    {
      id: 'customers',
      name: 'รายชื่อลูกค้า',
      link: '/customers/all/all',
      status: true
    },
    {
      id: 'admins',
      name: 'ผู้ดูและบบ',
      link: '/admins/all/all',
      status: true
    },
    {
      id: 'users',
      name: 'ผู้ใช้งาน',
      link: '/users/all/all',
      status: true
    },
    {
      id: 'business-settings',
      name: 'จัดการข้อมูลกิจการ',
      link: '/business-settings',
      status: true
    },
    {
      id: 'settings',
      name: 'ตั้งค่าระบบ',
      link: '/settings/admin-role-groups',
      status: true
    }
  ]

  return systemsAll
}
