// Containers
import OrdersContainer from 'delivery/modules/Orders/containers/OrdersContainer'

// Routes
import ReportsMenuRoutes from './routes/ReportsMenuRoutes'


const ReportsRoutes = [
  {
    path: '/:username/reports',
    component: OrdersContainer,
    routes: [
      ...ReportsMenuRoutes
    ]
  }
]

export default ReportsRoutes
