export const customerValue = ({ customer, lang='local' }) => {
  let firstNameLang, lastNameLang, companyNameLang, branchNameLang, nameLang, businessNameLang, contactNameLang, avatarName, addressLang, telFull, mobileFull
  let statusName, statusIcon, statusColor
  let telShort, mobileShort, corporateNameLang
  let authPhoneFull

  // gernaral
  if (customer) {
    firstNameLang = customer.firstName ? customer.firstName[lang] : null
    lastNameLang = customer.lastName ? customer.lastName[lang] : null
    businessNameLang = customer.businessName ? customer.businessName[lang] : null
    companyNameLang = customer.companyName ? customer.companyName[lang] : null
    branchNameLang = customer.branchName ? customer.branchName[lang] : null

    if (firstNameLang && lastNameLang) {
      contactNameLang = `${firstNameLang} ${lastNameLang}`
    } else if (firstNameLang && !lastNameLang) {
      contactNameLang = `${firstNameLang}`
    }

    if (!businessNameLang) {
      if (companyNameLang) {
        businessNameLang = companyNameLang
      } else {
        businessNameLang = contactNameLang
      }
    }

    if (customer.corporate) {
      if (businessNameLang) {
        nameLang = businessNameLang
      }
    } else {
      nameLang = businessNameLang ? businessNameLang : contactNameLang
    }

    if (nameLang) {
      avatarName = nameLang.split(' ')
    }

    // contact
    telShort = customer.tel && customer.tel.number ? `${customer.tel.number}` : null
    telFull = customer.tel && customer.tel.number ? `${customer.tel.number}` : null

    mobileShort= customer.mobile && customer.mobile.number ? `${customer.mobile.number}` : null
    mobileFull = customer.mobile && customer.mobile.number ? `${customer.mobile.number}` : null
    authPhoneFull = customer.authPhone && customer.authPhone.number ? `${customer.authPhone.number}` : null


    // Status
    if (customer.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      statusIcon = "public"
      statusColor = "success"

    } else if (customer.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (customer.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }

    if (customer.corporate) {
      corporateNameLang = 'นิติบุคล'
    } else {
      corporateNameLang = 'บุคลธรรมดา'
    }
  }

  return {
    ...customer,
    firstNameLang, lastNameLang, companyNameLang, nameLang, branchNameLang, contactNameLang, businessNameLang, corporateNameLang,
    avatarName,
    telFull, mobileFull,
    statusName, statusIcon, statusColor,
    mobileShort, telShort,
    authPhoneFull
  }
}


export const customerAddressValue = ({ customer, address, addresses_id, lang='local' }) => {
  let firstNameLang, lastNameLang, companyNameLang
  let nameLang, streetAddressLang, addressShort, addressFull



  if (addresses_id) {
    address = customer.addresses && customer.addresses.filter(address => address._id === addresses_id)[0]
  }

  if (address) {
    firstNameLang = address.firstName ? address.firstName[lang] : null
    lastNameLang = address.lastName ? address.lastName[lang] : null
    companyNameLang = address.companyName ? address.companyName[lang] : null
    streetAddressLang = address.streetAddress ? address.streetAddress[lang] : null

    if (address.corporate) {
      if (companyNameLang) {
        nameLang = companyNameLang
      }
    } else {
      nameLang = `${firstNameLang} ${lastNameLang}`
    }


    if (address.geo) {
      const { d, a, p, z } = address.geo
      let dFull, aFull, pFull
      let dShort, aShort, pShort

      if (p === 'กรุงเทพมหานคร') {
        dFull = `แขวง${d}`
        aFull = `เขต${a}`
        pFull = `${p}`

        dShort = `${d}`
        aShort = `${a}`
        pShort = `${p}`

      } else {
        dFull = `ตำบล${d}`
        aFull = `อำเภอ${a}`
        pFull = `จังหวัด${p}`

        dShort = `ต.${d}`
        aShort = `อ.${a}`
        pShort = `จ.${p}`
      }

      addressShort = `${streetAddressLang} ${dShort} ${aShort} ${pShort} ${z}`
      addressFull = `${streetAddressLang} ${dFull} ${aFull} ${pFull} ${z}`
    }

    return {
      ...address,
      nameLang,
      streetAddressLang,
      addressShort,
      addressFull
    }
  }
}


export const getShippingAddress = ({ address, pickupLocation }) => {
  let short

  if (address) {
    const { addressShort, nameLang, telFull, branchNameLang, branchNo } = address

    if (pickupLocation === 'inStorePickup') {
      short = `<b>${nameLang}</b> | ${telFull}<br />${addressShort}`

    } else if (pickupLocation === 'branchOtherPickup') {
      short = `<b>${nameLang}</b> | ${telFull}<br />${branchNameLang ? `สาขา${branchNameLang} ${branchNo ? `(${branchNo})` : ''}` : ''} ${addressShort}`

    } else if (pickupLocation === 'addressPickup') {
      short = `<b>${nameLang}</b> | ${telFull}<br />${addressShort}`

    } else {
      short = `<b>${nameLang}</b> | ${telFull}<br />${branchNameLang ? `สาขา${branchNameLang} ${branchNo ? `(${branchNo})` : ''}` : ''} ${addressShort}`
    }

  } else {
    if (pickupLocation === 'inStorePickup') {
      short = `<span class="color-error">*** กรุณาเพิ่มข้อมูลผู้ซื้อ</span>`
    } else if (pickupLocation === 'branchOtherPickup') {
      short = `<span class="color-error">*** กรุณาเพิ่มที่อยู่สาขาที่รับของ</span>`
    } else if (pickupLocation === 'addressPickup') {
      short = `<span class="color-error">*** กรุณาเพิ่มที่อยู่จัดส่ง</span>`
    }
  }

  return {
    addressShort: short
  }
}
